.header {
  height: 100px;
  background-color: var(--secondary-clr);
  display: flex;
  align-items: center;
  padding: 30px;
  justify-content: space-between;
  position: relative;
  position: fixed;
  inset: 0px;
  z-index: 999;
}

.header a,
.footer a,
.mobile_header a,
.mobile_nav a {
  color: white;
}

.nav {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 0;
}

.btns_wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.header_logo_wrapper h1,
.footer h1 {
  color: white;
  font-size: 50px;
}

.header_logo_wrapper h1 span,
.footer h1 span {
  color: var(--primary-clr);
}

.languege_indicator {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  border: var(--clr-2) solid 1px;
  gap: 10px;
  cursor: pointer;
}

.languege_indicator p {
  color: white;
}

.header_last_item {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dropdown {
  position: absolute;
  border-radius: 20px;
  background-color: white;
  right: 30px;
  top: 105px;
  width: 170px;
  border: 1px solid var(--clr-2);
  transition: ease-in 300ms all;
  position: fixed;
  z-index: 999;
}

.l_tab {
  padding: 15px;
  border-bottom: 1px solid var(--clr-2);
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.hide {
  display: none;
}

.mobile_header {
  display: none;
}

.header_logo_wrapper img,
.footer img {
  width: 100px;
}

.fa-gb {
  border-bottom: 0px;
}

@media (max-width: 600px) {
  .mobile_header {
    display: flex;
    background-color: var(--secondary-clr);
    height: 70px;
    align-items: center;
    position: fixed;
    inset: 0;
    width: 100%;
    padding: 15px;
    justify-content: space-between;
    z-index: 999;
  }
  .header {
    display: none;
  }

  .mobile_right_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    width: 80%;
  }

  .mobile_nav {
    transform: translateX(0%);
    background-color: var(--clr-1);
    position: fixed;
    height: 100%;
    left: 0;
    right: 20%;
    flex-direction: column;
    align-items: start;
    bottom: 0;
    padding: 20px;
    transition: transform 250ms ease-in-out;
    gap: 25px;
  }

  .mobile_nav svg {
    margin: 30px 0;
  }
  .close_menu {
    transform: translateX(-200%);
  }
}
