.fulltime_correct_score_Wrapper {
  margin-top: 100px;
  background-color: var(--clr-1);
}

.fulltime_correct_score_Wrapper .banner_wrapper {
  margin-top: 50px;
  margin-bottom: 0;
  padding-bottom: 50px;
}

.fulltime_correct_score_Wrapper .prediction_tab_navbar {
  padding-top: 50px;
}

.welcome_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  padding-top: 50px;
  text-align: center;
}

.welcome_wrapper h1 {
  font-size: 40px;
}

.welcome_wrapper p {
  font-size: 18px;
  width: 60%;
  margin: auto;
}

@media (max-width: 600px) {
  .fulltime_correct_score_Wrapper {
    margin-top: 70px;
  }

  .welcome_wrapper {
    padding: 30px 20px;
  }

  .welcome_wrapper h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 15px;
  }

  .welcome_wrapper p {
    width: 100%;
    line-height: 32px;
  }

  .fulltime_correct_score_Wrapper .prediction_tab_navbar {
    padding-top: 10px;
  }
}
