.news_wrapper {
  margin-top: 100px;
  background-color: var(--clr-1);
  padding-bottom: 50px;
}

.news_updates_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: white;
  row-gap: 50px;
  column-gap: 20px;
  padding: 50px 100px;
  justify-content: center;
  grid-template-areas:
    "header  header"
    "header  header"
    "itemone itemtwo"
    "itemthree itemfour"
    "footer footer"
    "footer footer"
    "itemfive itemsix"
    "itemseven itemeight";
}
#blog_item_1 {
  grid-area: header;
}
#blog_item_6 {
  grid-area: footer;
}

@media (max-width: 600px) {
  .news_wrapper {
    margin-top: 70px;
    padding: 30px 0;
  }
  .news_updates_wrapper {
    display: flex;
    flex-direction: column;
    padding: 50px 25px;
  }
}
