.about_us_wrapper {
  margin-top: 100px;
  background-color: var(--clr-1);
  padding: 50px 100px;
  color: white;
}

.about_title {
  color: white;
  font-size: 37px;
}

.about_title span {
  color: var(--primary-clr);
}

.about_info_text {
  line-height: 30px;
  margin-top: 20px;
}

.mini_title {
  font-size: 20px;
  margin-top: 30px;
}

.terms {
  margin-top: 25px;
}

@media (max-width: 600px) {
  .about_us_wrapper {
    margin-top: 70px;
    padding: 50px 20px;
  }

  .about_title {
    font-size: 28px;
  }
}
