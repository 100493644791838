.banner_container img {
  width: 100%;
}

.banner_wrapper {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  padding: 0 100px;
  gap: 50px;
}

.banner_container {
  width: 55%;
}

.banner_container h2,
.update_news_item_hero h2 {
  color: white;
  font-size: 30px;
  margin-top: 15px;
}

.telegram_sect_wrapper {
  width: 45%;
}

.telegram_sect {
  border-radius: 10px;
  background-color: var(--secondary-clr);
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: center;
  text-align: center;
  gap: 15px;
  height: 450px;
  width: 100%;
}

.telegram_sect h2 {
  font-size: 35px;
  line-height: 45px;
}

.telegram_sect .btn {
  flex-direction: row-reverse;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .banner_wrapper {
    flex-direction: column;
    padding: 0 20px;
  }

  .banner_container,
  .telegram_sect_wrapper {
    width: 100%;
  }
}
