.login_wrapper {
  background-color: var(--clr-1);
  margin-top: 100px;
  padding: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.login_wrapper h2 {
  font-size: 35px;
  font-weight: 800;
  margin-bottom: 20px;
}

.input_wrapper {
  width: 600px;
  border: 1px solid var(--clr-2);
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-top: 15px;
}

.input_wrapper input {
  border: none;
  width: 90%;
  outline: none;
  background-color: transparent;
  color: white;
  font-family: "Poppins", sans-serif;
}

.login_form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.password_wrapper .input_wrapper {
  flex-direction: row-reverse;
}

.password_wrapper svg {
  cursor: pointer;
}

.login_wrapper a {
  color: var(--clr-2);
}

.login_wrapper > p {
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  .login_wrapper {
    padding: 50px 20px;
    margin-top: 70px;
  }

  .login_form {
    gap: 20px;
  }

  .input_wrapper,
  .login_form .btn,
  .login_form,
  .login_form > div {
    width: 100% !important;
  }
}
