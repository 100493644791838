.gif_wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.desktop_gif {
  height: 110px;
}

.gif_wrapper svg {
  position: absolute;
  top: 10px;
  cursor: pointer;
  left: 20px;
}

.mobile_gif {
  display: none;
}

@media (max-width: 600px) {
  .desktop_gif {
    display: none;
  }

  .mobile_gif {
    display: block;
  }

  .gif_wrapper svg {
    display: none;
  }
}
