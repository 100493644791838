.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 0.2rem;
  padding: 10px;
  border-radius: 20px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
