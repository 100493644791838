.pop_up {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pop_up img {
  width: 300px;
  cursor: pointer;
}

.inner {
  position: relative;
}

.pop_up svg {
  position: absolute;
  top: 15px;
  left: 250px;
  cursor: pointer;
  z-index: 1000;
}
