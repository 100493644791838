.blog_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 50px 100px;
  color: white;
  gap: 50px;
}

.top_update_wrapper {
  width: 45%;
}

.top_update_wrapper h2 {
  margin-bottom: 20px;
}

.news_updates {
  width: 55%;
}

.top_update {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--clr-2);
  display: block;
}

.text_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.news_indicator {
  padding: 10px 15px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-clr);
  width: 100px;
  font-size: 14px;
  color: var(--clr-1);
}

.brief_text {
  font-size: 18px;
  color: white;
}

.date_text {
  font-size: 15px;
  color: var(--clr-2);
}

.new_updates_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

.new_updates_top_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.new_updates_top_wrapper .btn {
  border-radius: 50px;
}

@media (max-width: 600px) {
  .blog_wrapper {
    flex-direction: column;
    padding: 30px 20px;
  }

  .top_update_wrapper,
  .news_updates {
    width: 100%;
  }

  .new_updates_top_wrapper .btn {
    display: none;
  }
  .new_updates_wrapper {
    grid-template-columns: 1fr;
  }
}
