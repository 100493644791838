.update_news_item {
  margin-top: 100px;
  background-color: var(--clr-1);
  padding: 50px 100px;
  color: white;
}

.update_news_item .news_indicator {
  margin-top: 30px;
}

.update_news_item > h2 {
  font-size: 35px;
  margin-top: 10px;
}

.update_news_item .date_text {
  font-size: 17px;
  margin-bottom: 20px;
}

.block__content p {
  line-height: 1.8;
  margin-top: 15px;
  font-size: 18px;
}

.block__content h3 {
  font-size: 27px;
  margin-top: 15px;
}

.blog_desc_2 {
  margin-top: 30px;
}

.blog_desc_3 {
  margin-bottom: 30px;
}

.related_news_sect_wrapper h2 {
  font-size: 35px;
}

.related_news_sect {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .update_news_item {
    margin-top: 70px;
    padding: 30px 20px;
  }

  .related_news_sect_wrapper h2 {
    font-size: 30px;
  }

  .block__content h3 {
    font-size: 22px;
  }

  .related_news_sect {
    flex-direction: column;
  }
}
