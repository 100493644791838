.footer {
  background-color: var(--secondary-clr);
  padding: 50px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer_icons {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.footer_icons a svg {
  color: var(--clr-2) !important;
  font-size: 26px;
}

.footer nav {
  margin: 20px 0;
  margin-top: 30px;
}

@media (max-width: 600px) {
  .footer {
    align-items: start;
  }

  .footer .nav {
    flex-direction: column;
    height: auto;
    align-items: start;
    padding: 15px 0px;
  }
}
