.testimonials_wrapper {
  background-color: var(--clr-1);
  margin-top: 100px;
}

.testimonials_wrapper .banner_wrapper {
  margin-bottom: 0;
  padding-bottom: 50px;
}

.testimony_sect {
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.testimony_sect h2 {
  margin: 10px 0;
}

.testimony_sect img {
  height: 500px;
  width: auto;
  margin: auto;
}

@media (max-width: 600px) {
  .testimonials_wrapper {
    margin-top: 70px;
  }
  .testimony_sect {
    padding: 30px 20px;
  }
}
