.prediction_tab_navbar {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 100px;
}

.prediction_tab_navbar a,
.tip_wrapper a,
.modal_container a {
  color: white;
  border: 1px solid var(--clr-2);
  padding: 10px;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .prediction_tab_navbar {
    padding: 0 20px;
    padding-top: 40px;
    justify-content: start;
  }
  .pt_prediction_tab_navbar {
    width: 1050px;
  }
  .en_prediction_tab_navbar {
    width: 750px;
  }
  .es_prediction_tab_navbar {
    width: 1100px;
  }
}
