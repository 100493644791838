* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

:root {
  --primary-clr: #ffdf1b;
  --secondary-clr: #126e51;
  --clr-1: #333;
  --clr-2: #9c9c9c;
}

input,
select {
  font-size: 16px;
}

body {
  font-family: "Poppins", sans-serif;
}

a,
li {
  list-style: none;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}
