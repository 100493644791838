.home_page_wrapper {
  background-color: var(--clr-1);
  margin-top: 100px;
  padding-bottom: 50px;
}

.home_intro_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

.home_intro_wrapper h1 {
  font-size: 3.5rem;
  width: 40%;
  margin: auto;
  text-align: center;
  color: white;
  line-height: 65px;
}

.home_intro_wrapper p {
  color: white;
  width: 80%;
  margin: auto;
  text-align: center;
  margin-top: 20px;
}

.home_intro_join_btns_wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.trusted_by_text {
  color: var(--clr-2) !important;
}

.testimony_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  margin-top: 20px;
}

.proof_wrapper {
  border: 1px solid var(--clr-2);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.proof_wrapper .btn {
  margin-top: 10px;
  background-color: var(--primary-clr);
  color: var(--clr-1);
  font-weight: 600;
  height: 30px;
  width: 100px;
}

.testi_text {
  color: white;
  font-size: 40px;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .home_page_wrapper,
  .league_standing_wrapper {
    margin-top: 70px;
  }
  .home_intro_wrapper {
    padding: 20px;
  }
  .home_intro_wrapper p {
    width: 100%;
    text-align: start;
    line-height: 30px;
  }
  .home_intro_wrapper h1 {
    font-size: 1.8rem;
    line-height: 40px;
    width: 100% !important;
    text-align: start;
  }
  .testimony_wrapper {
    grid-template-columns: 1fr;
  }

  .home_intro_join_btns_wrapper {
    flex-direction: column;
  }

  .prediction_sect_wrapper,
  .display_predictions_parent_wrapper {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .prediction_sect_wrapper::-webkit-scrollbar,
  .display_predictions_parent_wrapper::-webkit-scrollbar {
    display: none;
  }
}
