.contact_us_wrapper {
  padding: 50px 100px;
  margin-top: 100px;
  background-color: var(--clr-1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.contact_us_bold_text {
  font-size: 40px;
}

.contact_us_wrapper form {
  margin-top: 50px;
}

.contact_us_wrapper textarea {
  background-color: transparent;
  border: 1px solid var(--clr-2);
  width: 600px;
  border-radius: 8px;
  height: 300px;
  padding: 15px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: white;
  outline: none;
}

.text_area_wrapper {
  display: flex;
  flex-direction: column;
}

.text_area_wrapper label {
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .contact_us_wrapper {
    margin-top: 70px;
    padding: 40px 20px;
  }
  .contact_us_wrapper textarea {
    width: 100%;
  }
}
