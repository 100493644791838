.over2_wrapper {
  margin-top: 100px;
  background-color: var(--clr-1);

  padding-bottom: 50px;
}

.over_2_title {
  color: white;
  text-align: center;
  font-size: 35px;
  margin-top: 40px;
}

.display_predictions {
  border-radius: 10px;
  padding: 30px 100px;
  color: white;
}

.display_predictions h3 {
  font-size: 18px;
  font-weight: 600;
}

.display_predictions_header,
.display_predictions_body,
.display_prediction_footer {
  background-color: var(--primary-clr);
  color: var(--clr-1);
  display: flex;
  align-items: center;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 20px;
}

.display_predictions_body {
  border: none;
  background-color: transparent;
  color: white;
}

.display_predictions_body .time_wrapper p {
  margin-left: 5px;
}
.display_predictions_body .result_wrapper p {
  margin-left: 15px;
}
.display_predictions_body .status_wrapper p {
  display: flex;
  align-items: center;
  gap: 5px;
}

.display_predictions_header_icon_sect {
  width: 10%;
}

.match_wrapper {
  width: 40%;
}

.tip_wrapper,
.time_wrapper,
.result_wrapper,
.status_wrapper {
  width: 12.5%;
}

.display_prediction_footer {
  justify-content: space-between;
  border-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.display_prediction_footer .btn {
  font-weight: 600;
}

.no_data {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
}

@media (max-width: 600px) {
  .display_predictions_header,
  .display_prediction_footer {
    padding: 15px;
  }
  .over2_wrapper {
    margin-top: 70px;
  }
  .over_2_title {
    font-size: 27px;
    text-align: start;
    margin: 0 20px;
    margin-top: 30px;
  }

  .display_predictions {
    width: 1300px;
    padding: 30px 20px;
  }

  .display_predictions h3 {
    font-size: 16px;
  }

  .display_predictions_body p {
    font-size: 15px;
  }
}
