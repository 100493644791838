.league_standing_wrapper {
  margin-top: 100px;
  background-color: var(--clr-1);
  padding: 50px 0;
}

.table_wrapper {
  width: 85%;
  margin: auto;
}

@media (max-width: 600px) {
  .league_standing_wrapper {
    margin-top: 70px;
  }

  .table_wrapper {
    width: 95%;
  }
}
