.modal_container {
  background-color: var(--secondary-clr);
  width: 40%;
  padding: 30px;
  border-radius: 10px;
  color: white;
}

.modal_header_sect {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal_container svg {
  position: static;
}

.modal_container h3 {
  font-size: 18px;
  font-weight: 500;
}

.modal_container a {
  width: 100%;
  display: block;
  margin-top: 20px;
  text-align: center;
}

.modal_container p {
  font-size: 14px;
  margin: 20px 0;
  line-height: 27px;
  font-weight: 400;
}

.modal_container span {
  color: var(--clr-1);
  font-weight: 600;
  font-size: 15px;
}
@media (max-width: 600px) {
  .modal_container {
    width: 90%;
  }
  .modal_container a {
    font-size: 14px;
  }
}
